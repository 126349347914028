@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "euclid";
    src: url("./fonts/Euclid\ Circular\ A\ Regular.ttf");
}

input[type="file"]::before {
    content: 'Upload';
    border: 0px;
    padding: 0.4em 0.6em;
    margin-right: 0.5em;
    border-radius: 0.3em;
    background-color: #D9D9D9;
    font-weight: 600;
    transition: 1s;
    text-align: "left";
    -webkit-rtl-ordering: "left";
}

input[type="file"]::file-selector-button {
    border: 0px;
    padding: 0.4em 0.6em;
    border-radius: 0.3em;
    background-color: #D9D9D9;
    font-weight: 'bold';
    transition: 1s;
    text-align: left;
    -webkit-rtl-ordering: left;
    content: 'Upload';
    display: none;

}

.styledRadio:checked {
    background-image: url('data:image/svg+xml,<svg viewBox="0 0 16 16" fill="white" xmlns="http://www.w3.org/2000/svg"><path d="M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z"/></svg>');
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}
.teams {
  /* background: #c6a85c; */
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  /* padding: 3.5rem 0; */
  margin: 1.5rem 3.5rem;
}

.overlay {
  background: #d0efed;
}

.team-card {
  background: #c6a85c;
  /* padding-right: 2px; */
  margin: 0 1.3rem;
  /* width: 70%; */
  padding-bottom: 2rem;
  height: fit-content;
  /* margin: 0 auto; */

  /* margin-left: 2rem; */
  /* margin: 0 auto; */
}

.team-card img {
  width: 100%;
  margin-bottom: 1.5rem;
}

.column-parent {
  margin: 0 auto;
}
.mobile-slider {
  display: none;
}

@media only screen and (max-width: 768px) {
  .top h4 {
    font-size: 18px;
  }

  .top .font-normal {
    display: none;
  }

  .mobile-slider {
    display: block;
    margin: 0 auto;
    overflow: hidden;
  }

  .column-parent {
    display: none;
  }
}
